@import '../../../styles/variables.scss';

.container {
  // color: $gray-1;
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.label {
  display: inline-block;
  width: 136px;
  flex-shrink: 0;
  font-weight: 500;
  margin-right: 1rem;
  @media (max-width: 640px) {
    width: 7rem;
  }
}

.value {
  font-weight: 400;
  max-width: calc(100% - 10.75rem);
}
