@import '../../../styles/variables.scss';

.timer {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: $gray-1;
  height: 54px;
}

.warning {
  color: $red;
}
