@import '../../../styles/variables';

.badge {
  height: 1.5rem;
  background-color: white;
  border: 1px solid $gray-14;
  border-radius: 0.25rem;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  font-size: 14px;

  >span {
    text-wrap: nowrap;
  }

  &.fz223 {
    background-color: rgba(#3C83F6, 0.04);
    border: 1px solid #3C83F6;
  }
  
  &.eshop {
    background-color: rgba(#27ae60, 0.04);
    border: 1px solid #27ae60;
  }
  
  &.com {
    background-color: rgba(#00B35F, 0.04);
    border: 1px solid #00B35F;
  }

  @media only screen and (max-width: 800px) {
    // height: 40px;
    // padding: 6px 12px;
  }
}
