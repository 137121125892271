@import '../../../styles/variables.scss';

.duration {}

.delimiter {
  padding: 0 5px;
}

.title {
  position: relative;
  border: 1px solid $gray-14;
  border-radius: 0.25rem;
  width: 56px;
  height: 50px;
  padding: 3px 11px 18px 11px;

  &:after {
    content: attr(data-content);
    position: absolute;
    width: 100%;
    top: 20px;
    left: 0;
    color: $gray-5;
    font-size: 12px;
    text-align: center;
  }
}
