@import '../../../styles/variables';

.info {
  margin-bottom: 1rem;
}

.button {
  width: 15rem;
}

.form {
  width: 100%;
  margin-bottom: 3rem;

  > .field {
    margin-bottom: 12px;
  }
}

.footer {
  position: absolute;
  right: 2rem;
  bottom: 2rem;

  .sendBtn {
    width: 15rem;
  }

  .sendBtn:hover {
    color: $white;
    color: var(--white);
  }
}
