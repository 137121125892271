@import '../../styles/variables';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: var(--white);
  gap: 7.5rem;

  @media (max-width: 1366px) {
    margin: 0 60px;
    max-width: 1246px;
    width: calc(100% - 120px);
  }

  @media (max-width: 1080px) {
    gap: 80px;
    margin: 0;
    width: 100%;
  }
}

.stub {
  width: 100%;
  height: 100vh;
}

.bg,
.purchases,
.eko,
.tariffs,
.cashback,
.statistics {
  width: 100%;

  .wrapper {
    max-width: 1440px;
    margin: 0 auto;

    h2 {
      font-size: 60px;
      font-weight: 500;
      line-height: 66px;
      margin: 0;
      color: $blue-8;

      @media (max-width: 1080px) {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -2px;
      }
    }

    h3 {
      font-size: 40px;
      font-weight: 500;
      line-height: 44px;
      margin: 0;
      color: $blue-8;

      @media (max-width: 1080px) {
        font-size: 28px;
        line-height: 31px;
        letter-spacing: -1px;
      }
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      margin: 0;
      color: $blue-7;
    }

    caption {
      font-size: 70px;
      font-weight: 500;
      line-height: 77px;
      letter-spacing: -2px;      
      margin: 0;
      color: $blue-8;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
      color: $blue-7;

      @media (max-width: 1080px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    @media (max-width: 1360px) {
      padding: 0 1rem;
      max-width: 1246px;
    }

    @media (max-width: 1080px) {
      padding: 0;
    }
  }
  @media (max-width: 1080px) {
    margin: 0 2rem;
    max-width: 1016px;
    width: calc(100% - 4rem);
  }
  @media (max-width: 375px) {
    margin: 0 1.25rem;
    width: calc(100% - 2.5rem);
  }
}

.bg {
  max-width: 1440px;
  position: relative;
  margin: 2rem 0 0;
  border-radius: 1.25rem;
  border: 1px solid $gray-15;
  background: $gray-8;

  .wrapper {
    justify-content: center;
    align-items: center;
    >div {
      &:first-of-type {
        gap: 1rem;
        width: 561px;
        
        >h2 {
          color: $blue-2-2;
        }
        .action {
          margin: 1rem 0 0;
          width: 327px;
          align-items: center;
          gap: 1rem;
          @media (max-width: 561px) {
            width: 100%;
            padding: 0 0.5rem;
            overflow: hidden;
            >span {
              text-wrap: auto;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          @media (max-width: 324px) {
            >span {
              width: calc(100% - 1rem);
            }
          }
        }

        @media (max-width: 1080px) {
          padding-left: 80px;
        }
        @media (max-width: 768px) {
          padding-left: 0;
        }
        @media (max-width: 561px) {
          width: 100%;
        }
      }
      &.logo {
        background: url(/assets/main/top_tornado.png) no-repeat 50% 50%;
        height: 568px;
        width: 759px;
        background-size: contain;

        @media (max-width: 1366px) {
          height: 483px;
          width: 646px;
        }
        @media (max-width: 1080px) {
          height: 388px;
          width: 518px;
        }
        @media (max-width: 768px) {
          height: 381px;
          width: 509px;
        }
        @media (max-width: 500px) {
          height: calc(100vw*0.6);
          width: 100%;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 0 1rem 1rem 1rem;
    }
  }
}

.statistics {
  .wrapper {
    gap: 5rem;
    justify-content: center;
    >div {
      width: fit-content;
      align-items: center;
    }
    @media (max-width: 561px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.purchases {
  .wrapper {
    padding: 0;
    .tabs {
      gap: 1.25rem;
      .tab {
        align-items: center;
        justify-content: center;
        flex-basis: 220px;
        flex-shrink: 0;
        padding: 19px 24px;
        border-top-left-radius: 1.25rem;
        border-top-right-radius: 1.25rem;
        border: 1px solid $gray-15;
        border-bottom: none;
        background: $gray-15;
        color: $blue-8;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        cursor: pointer;
        user-select: none;
        &.active, &:hover {
          background: $gray-8;
        }
      }
    }
    .tabBody {
      gap: 40px;
      border-top-right-radius: 1.25rem;
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      border: 1px solid $gray-15;
      background: $gray-8;
      padding: 40px;
      .stats {
        gap: 30px;
        width: fit-content;
        >div {
          width: fit-content;
        }
        .action {
          height: 4rem;
          width: 230px;
        }
        @media (max-width: 1080px) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        @media (max-width: 768px) {
          >div {
            display: none;
          }
        }
      }
      .purchasesList {
        gap: 2rem;
        >a {
          >div {
            box-shadow: none;
            &:hover {
              box-shadow: 0px 4px 8px 5px $gray-15;
            }
  
            >div[class*="main"] {
              padding: 0 2rem;
              width: calc(100% - 292px);
  
              >div {
                &[class*="spacer"], &[class*="number"] {
                  display: none;
                }
                &[class*="startPriceRow"] {
                  >div {
                    width: 100%;
                    >div {
                      width: 100%;
                    }
                  }
                }
                @media (max-width: 768px) {
                  >div {
                    flex-wrap: wrap;
                    gap: 0.25rem;
                    display: flex;
                    :first-child {
                      width: 100%;
                    }
                  }
                }
              }
              @media (max-width: 768px) {
                width: 100%;
                gap: 0.75rem;
                padding: 0 1rem;
              }
            }
            >div[class*="side"] {
              max-width: 292px;
              flex-basis: 292px;
              min-width: 292px;
              padding: 1rem;
              gap: 0.75rem;
              border-radius: 1rem;
              background: $gray-8;
              margin-right: 2rem;
              font-family: $font-primary;
  
              >div[class*="phase"] {
                flex-grow: 1;
                display: flex;
                >div {
                  display: flex;
                  flex-direction: column;
                  >div[class*="title"] {
                    color: $blue-2-2;
                    font-weight: 500;
                  }
                  >div[class*="date"] {
                    margin-bottom: auto;
                    font-weight: 500;
                    font-family: $font-primary;
                    @media (max-width: 768px) {
                      margin-bottom: 0.75rem;
                    }
                  }
                }
              }
  
              >img {
                display: none;
              }
              >div[class*="publishDate"] {
                margin: 0;
                color: $gray-3;
              }
              @media (max-width: 768px) {
                margin: 0;
                flex-basis: 185px;
                max-width: initial;
                min-width: initial;
                width: calc(100% - 2rem);
                margin: 0 1rem;
              }
            }
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-items: center;
              gap: 1.5rem;
              margin-bottom: 0;
            }
          }
          @media (max-width: 768px) {
            max-width: 320px;
            height: 100%;
          }
          @media (max-width: 561px) {
            width: 320px;
            flex-shrink: 0;
          }
        }
        @media (max-width: 768px) {
          width: 100%;
          overflow: auto;
          flex-direction: row;
        }
      }
      @media (max-width: 1080px) {
        flex-direction: column;
      }
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 1.5rem;
        gap: 1.5rem;
        border-radius: 1.25rem;
      }
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
}

.cashback {
  .wrapper {
    padding: 0 156px;
    align-items: center;
    >div {
      &:first-of-type {
        gap: 1rem;
        width: 420px;

        @media (max-width: 420px) {
          width: 100%;
        }
      }
      &:nth-of-type(2) {
        background: url(/assets/main/plus_percent.png) no-repeat 50% 50%;
        width: 708px;
        height: 468px;
        background-size: contain;

        @media (max-width: 1080px) {
          height: 340px;
          width: 514px;
        }
        @media (max-width: 514px) {
          height: calc((100vw - 2rem) * 0.6);
          width: 100%;
        }
      }
      @media (max-width: 768px) {
        align-items: center;
        text-align: center;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
      padding: 0 1rem;
    }
  }
}

.tariffs {
  .wrapper {
    gap: 2.5rem;

    .tariffsList {
      gap: 1rem;

      >div {
        border-radius: 1.25rem;
        background: $gray-8;
        border: 1px solid $gray-15;
        padding: 2rem 1.5rem;
        height: 300px;

        >p {
          &:nth-of-type(2) {
            margin-top: auto;
          }
          @media (max-width: 1251px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        >div {
          gap: 0.25rem;
          margin: 60px 0 36px;
          >h3 {
            @media (max-width: 1251px) {
              font-size: 28px;
              line-height: 31px;
              letter-spacing: -1px;
            }
          }
        }
      }
      @media (max-width: 940px) {
        flex-wrap: wrap;
        >div {
          width: 48%;
        }
      }
      @media (max-width: 500px) {
        >div {
          width: 100%;
          gap: 1rem;
          height: fit-content;
          > div {
            margin: 0;
          }
        }
      }
    }

    .description {
      gap: 2rem;
    }

    .links {
      gap: 1rem;      
      >div {
        border-radius: 1.25rem;
        border: 1px solid $gray-15;
        padding: 0.75rem 1.25rem 0.75rem 1rem;
        background: $gray-8;
        gap: 0.5rem;
        align-items: center;

        &:hover {
          background: $gray-15;
        }

        >span {
          display: block;
          width: 56px;
          height: 56px;
          flex-shrink: 0;
          background: url(/assets/main/diploma.png) no-repeat 50% 50%;
        }
        a, a:hover, a:visited {
          color: $blue-8;
        }
        @media (max-width: 1366px) {
          max-width: 340px;
          width: fit-content;
        }
      }
      @media (max-width: 1366px) {
        flex-wrap: wrap;
      }
    }
  }
}

.eko {
  background: $white;
  margin-bottom: 7.5rem;

  p {
    font-weight: 500;
  }

  .wrapper {
    gap: 1.5rem;

    >div {
      gap: 2rem;
    }
  }

  p {
   max-width: 44rem;
  }

  @media (max-width: 1080px) {
    margin-bottom: 80px;
  }

  @media (max-width: 768px) {
    .wrapper {
      gap: 1rem;

      >div {
        gap: 11px;
      }
    }
  }
  @media (max-width: 500px) {
    .wrapper {
      >div {
        flex-wrap: wrap;
        >div {
          width: 48%;
        }
      }
    }
  }
}

.ecoItem {
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
  padding: 2.5rem 1.5rem;
  background: $gray-8;
  gap: 1.5rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: $gray-15;
  }
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.25rem;
    width: 7.5rem;
    height: 7.5rem;
    box-shadow: 0px 4px 4px 0px #272E474D;
    
    &.houses {
      background: url(/assets/main/houses.png) no-repeat 50% 50%, linear-gradient(332.81deg, rgba(39, 46, 71, 0.8) 12.93%, rgba(94, 112, 173, 0.8) 184.67%),
      radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      background-size: contain;
    }
    
    &.badge {
      background: url(/assets/main/badge.png) no-repeat 50% 50%, linear-gradient(332.81deg, rgba(39, 46, 71, 0.8) 12.93%, rgba(94, 112, 173, 0.8) 184.67%),
      radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      background-size: contain;
    }
    
    &.folder {
      background: url(/assets/main/folder.png) no-repeat 50% 50%, linear-gradient(332.81deg, rgba(39, 46, 71, 0.8) 12.93%, rgba(94, 112, 173, 0.8) 184.67%),
      radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      background-size: contain;
    }
    
    &.note {
      background: url(/assets/main/note.png) no-repeat 50% 50%, linear-gradient(332.81deg, rgba(39, 46, 71, 0.8) 12.93%, rgba(94, 112, 173, 0.8) 184.67%),
      radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      background-size: contain;
    }
    @media (max-width: 768px) {
      width: 56px;
      height: 56px;
    }
  }
  @media (max-width: 768px) {
    padding: 18px 11px;
    border-radius: 9px;
  }
}
