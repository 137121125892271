@import '../../../styles/variables.scss';

$estp-color-phase-receive: #27ae60;
$estp-color-phase-consider: #b48240;
$estp-color-phase-bidding: #2d9cdb;
$estp-color-phase-finished: #bdbdbd;

.phase {
}

.title,
.date {
  font-size: 16px;
  font-weight: 500;
}

.title {
  margin-bottom: 0.5rem;

  &.receive {
    color: $estp-color-phase-receive;
  }

  &.consider,
  &.summingUp {
    color: $estp-color-phase-consider;
  }

  &.bidding,
  &.opening {
    color: $estp-color-phase-bidding;
  }

  &.finished,
  &.dealing {
    color: $estp-color-phase-finished;
  }
}

.date {
  margin-bottom: 1.25rem;
}

.timer {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: $gray-1;
  // margin-bottom: 72px;

  .warning {
    color: #ed7070;
  }

  .icon {
    margin-right: 14px;
    position: relative;
    top: 4px;

    .normal {
      fill: #333;
    }

    .warning {
      fill: #ed7070;
    }
  }
}
