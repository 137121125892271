@import '../../../../styles/variables';

.link {
  color: $gray-1;
  &:visited {
    color: $gray-1;
  }
}
.card {
  margin-bottom: 0.75rem;
  padding: 2rem 0;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: $font-primary;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  box-shadow: 0px 4px 4px 0px $gray-15;

  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 4px 32px 6px 32px;
  width: 900px;
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    max-width: 831px;
  }

  @media (max-width: 640px) {
    padding: 0.25rem 1.5rem 0.375rem;
  }

  .badges {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    @media (max-width: 1199px) {
      flex-wrap: wrap;

      > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  .organizer {}

  .startPriceRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 640px) {
      flex-wrap: wrap;
      height: unset;
    }
  }

  .regions>div,
  .title>div,
  .number>div,
  .procedure>div,
  .startPrice {
    display: flex;
    gap: 1rem;
    >span {
      &:first-of-type {
        display: inline-block;
        width: 136px;
        flex-shrink: 0;
        font-weight: 500;
        @media (max-width: 640px) {
          width: 7rem;
        }
      }
    }
    .value {
      font-weight: 400;
      max-width: calc(100% - 10.75rem);
    }
  }
}
.startPrice {
  .value {
    font-weight: 500;
    font-size: 16px;
  }
}

.side {
  display: flex;
  flex-direction: column;
  max-width: 352px;
  flex-basis: 352px;
  min-width: 352px;
  padding: 1.5rem 5rem 0 2rem;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding: 1.5rem 1.5rem 0;
  }

  .phase {
    margin-bottom: auto;
  }

  .publishDate {
    color: $gray-3;
    margin-top: 0.75rem;
  }
}

.spacer {
  flex-grow: 1;
}

.delimiter {
  margin-left: 0.5rem;
}
