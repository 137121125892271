@import '../styles/variables';

.field {
  font-size: 14px;
  color: $gray-1;
  color: var(--gray-1);
  padding: 0.75rem 1rem 0 1rem;
  height: 100%;
  width: 100%;
  background: $white;
  background: var(--white);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  outline: none;
  resize: none;

  input {
    display: none;
  }

  &.error:not(:focus) {
    border: 1px solid $red;
    border: 1px solid var(--red);
    outline: 1px solid $red;
    outline: 1px solid var(--red);
  }
}

.list {
  width: 100%;
  padding-inline-start: 1rem;

  li div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  li div div:nth-child(1) {
    width: 85%;
    word-break: break-all;
  }

  li div div:nth-child(2) {
    white-space: nowrap;
    margin-left: auto;
  }
}

.errorMessage {
  margin-left: 16px;
}

.uploadButton {
  margin-bottom: 1rem;
}
