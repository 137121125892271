@import '../../../styles/variables';

.badge {
  cursor: pointer;
  position: relative;

  span {
    text-wrap: nowrap;
  }
}

.icon {
  display: inline-block;
  fill: currentcolor;
  line-height: 1;
  stroke: currentcolor;
  stroke-width: 0;
  margin-right: 8px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 4rem;
  width: 14rem;
  // top: 3rem;
  color: $blue-2;
  color: var(--blue-2);
  left: 0;
  z-index: 1;
  background-color: $white;
  background-color: var(--white);
  box-shadow: 0px 10px 30px rgba(63, 169, 245, 0.15);
  border: 1px solid transparent;
  border-radius: 0.75rem;

  @media only screen and (max-width: 800px) {
    height: 40px;
    padding: 6px 12px;
  }
}

.fz223 {
  color: #3C83F6;
  white-space: nowrap;
}

.eshop {
  color: #27ae60;
}

.com {
  color: #00B35F;
}
