@import '../../../styles/variables';

.badge {
  color: $gray-1;
  color: var(--gray-1);
  cursor: pointer;
  position: relative;
}

.link {
  color: $blue-2;
  color: var(--blue-2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 4rem;
  width: 12rem;
  // top: 3rem;
  left: 0;
  background-color: $white;
  background-color: var(--white);
  box-shadow: 0px 10px 30px rgba(63, 169, 245, 0.15);
  border: 1px solid transparent;
  border-radius: 0.75rem;

  @media only screen and (max-width: 800px) {
    height: 40px;
    padding: 6px 12px;
  }
}
