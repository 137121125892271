@import '../../../styles/variables';

.badge {
  color: #828282;
  cursor: pointer;
  position: relative;
}

.icon {
  display: inline-block;
  fill: currentcolor;
  line-height: 1;
  stroke: currentcolor;
  stroke-width: 0;
  margin-right: 8px;
}

.linkList {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 300px;
  width: 30rem;
  overflow-y: auto;
  word-break: break-word;
  left: 0;
  z-index: 1;
  background-color: $white;
  background-color: var(--white);
  box-shadow: 0px 10px 30px rgba(63, 169, 245, 0.15);
  border: 1px solid transparent;
  border-radius: 0.75rem;

  > a {
    display: block;
    color: $blue-2;
    color: var(--blue-2);
    cursor: pointer;

    margin-top: 1rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 800px) {
    height: 40px;
    padding: 6px 12px;
  }
}
