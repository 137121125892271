@import '../../../styles/variables.scss';

.link {
  max-width: calc(100% - 10.75rem);
  color: $gray-1;
}

.text {
  word-break: break-word;
  word-wrap: break-word;
  width: 100%;
}
