.container {
  color: #000;
  display: inline-flex;
  flex-direction: row;

  &.mute {
    color: #7b7b7b;
  }
}

.value {
  display: inline-block;
  white-space: nowrap;
}

.strong {
  font-weight: 500;
}
